import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import { DEACTIVATED } from "../../../utils/constants";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";

const DeleteDeviceDialog = (props) => {
  const {
    isOpen = false,
    devices,
    batchUpdate,
    handleAfterDelete,
    closeDialog,
    tenantId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const handleClose = () => {
    closeDialog();
  };

  const handleConfirmDelete = async () => {
    const allSubmitValues = [];
    devices.forEach((device) => {
      const submitValues = {
        tenant: { id: tenantId },
        id: device.id,
        name: device.name,
        status: DEACTIVATED,
      };

      allSubmitValues.push(submitValues);
    });

    const allResponses = await batchUpdate(allSubmitValues);

    allResponses.map((response) => {
      if (response?.data?.updateDevice?.device) {
        enqueueSnackbar(
          intl.formatMessage({ id: "success_del" }) +
            ` ${intl.formatMessage({ id: "device" })}: ` +
            response.data.updateDevice.device.name,
          {
            variant: "warning",
          }
        );
      } else {
        enqueueSnackbar(intl.formatMessage({ id: "error" }), {
          variant: "error",
        });
      }
    });

    handleClose();
    handleAfterDelete(allResponses);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "delete_device" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {intl.formatMessage({ id: "confirm_delete" })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleConfirmDelete}
            variant={"contained"}
            sx={{
              backgroundColor: colors.red500,
              ":hover": {
                backgroundColor: colors.red800,
              },
            }}
          >
            {intl.formatMessage({ id: "delete" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDeviceDialog;
