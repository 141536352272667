import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import {
  SelectDisplayProps,
  inputLabelSx,
  menuPaperSx,
  inputLabelProps,
  inputProps,
  ACTIVE,
  ENUM_ON,
  ENUM_OFF,
} from "../../../utils/constants";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
  BasicSelectWithCheckMark,
  BasicTextField,
  InfoIcon,
} from "@datwyler/shared-components";

const formDefaults = {
  name: "",
  description: "",
  emails: "",
  attribute_name: "",
  type: "",
  value: "",
  severity: "",
};

const AlarmRuleDialog = (props) => {
  const {
    isOpen = false,
    device,
    closeDialog,
    tenantId,
    addAlarmRule,
    addAlarmRuleResponseData,
    resetAddAlarmRuleData,
    updateAlarmRule,
    updateAlarmRuleResponseData,
    resetUpdateAlarmRuleData,
    setSelectedAlarmRules,
    selectedAlarmRules: [selectedRule], // only 1 selected rule when updating
    refetchAlarmRules,
  } = props;
  const [attributeOptions, setAttributeOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const form = useForm({ defaultValues: formDefaults });
  const name = form.watch("name");
  const attributeName = form.watch("attribute_name");
  const type = form.watch("type");
  const value = form.watch("value");
  const severity = form.watch("severity");

  useEffect(() => {
    if (selectedRule) {
      form.reset({
        name: selectedRule.name,
        description: selectedRule.description,
        emails: selectedRule.emails.join(", "),
        attribute_name: selectedRule.properties?.attribute_name,
        type: selectedRule.properties?.type,
        value: selectedRule.properties?.value,
        severity: selectedRule.severity,
      });
    } else form.reset(formDefaults);
  }, [selectedRule]);

  useEffect(() => {
    if (addAlarmRuleResponseData?.addAlarmRule?.alarmRule) {
      refetchAlarmRules();
      enqueueSnackbar(intl.formatMessage({ id: "alarm_rule_add_success" }), {
        variant: "success",
      });
      resetAddAlarmRuleData();
      handleClose();
    }
  }, [addAlarmRuleResponseData]);

  useEffect(() => {
    refetchAlarmRules();
    if (updateAlarmRuleResponseData?.updateAlarmRule?.alarmRule && isOpen) {
      enqueueSnackbar(intl.formatMessage({ id: "alarm_rule_update_success" }), {
        variant: "success",
      });
      resetUpdateAlarmRuleData();
      handleClose();
    }
  }, [updateAlarmRuleResponseData]);

  useEffect(() => {
    const allowedOptions = ["modem_temperature", "modem_signal", "status"];
    if (device?.telemetry) {
      const attributeOptions = device.telemetry.reduce((tempOptions, tel) => {
        if (allowedOptions.includes(tel.name)) {
          tempOptions.push({
            priority: 0,
            value: tel.name,
            label: intl.formatMessage({ id: tel.name }),
          });
        }
        return tempOptions;
      }, []);
      setAttributeOptions(attributeOptions);
    } else {
      setAttributeOptions([]);
    }
  }, [device]);

  const handleSave = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const submitValues = form.getValues();
      transformBeforeSend(submitValues);

      if (!selectedRule) {
        addAlarmRule({
          variables: {
            input: submitValues,
          },
        });
      } else {
        updateAlarmRule({
          variables: {
            input: submitValues,
          },
        });
      }
    }
  };

  const transformBeforeSend = (submitValues) => {
    /* Properties
    {
      "type": "GT",
      "value": "20",
      "attribute_name": "modem_temperature"
    }
    */
    if (!selectedRule) {
      submitValues.device = { id: device.id };
    } else {
      submitValues.id = selectedRule.id;
    }

    submitValues.emails =
      submitValues.emails?.replace(" ", "").split(",") || [];
    submitValues.properties = {
      type: submitValues.type,
      value: submitValues.value,
      attribute_name: submitValues.attribute_name,
    };
    submitValues.status = ACTIVE;
    submitValues.tenant = { id: tenantId };
    delete submitValues.type;
    delete submitValues.value;
    delete submitValues.attribute_name;
  };

  const conditionOptions = [
    { priority: 0, value: "GT", label: intl.formatMessage({ id: "GT" }) },
    { priority: 1, value: "GTE", label: intl.formatMessage({ id: "GTE" }) },
    { priority: 2, value: "LT", label: intl.formatMessage({ id: "LT" }) },
    { priority: 3, value: "LTE", label: intl.formatMessage({ id: "LTE" }) },
    { priority: 4, value: "NE", label: intl.formatMessage({ id: "NE" }) },
  ];

  const conditionOptionsNE = [
    { priority: 0, value: "NE", label: intl.formatMessage({ id: "not" }) },
  ];

  const criticalityOptions = [
    {
      priority: 1,
      value: "WARNING",
      label: intl.formatMessage({ id: "warning" }),
    },
    {
      priority: 2,
      value: "CRITICAL",
      label: intl.formatMessage({ id: "critical" }),
    },
  ];

  const valueOptions = [
    {
      priority: 0,
      value: ENUM_ON,
      label: intl.formatMessage({ id: "device_on" }),
    },
    {
      priority: 1,
      value: ENUM_OFF,
      label: intl.formatMessage({ id: "device_off" }),
    },
  ];

  const rulesRequired = {
    required: {
      type: "required",
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };

  const handleClose = () => {
    form.reset(formDefaults);
    setSelectedAlarmRules([]);
    closeDialog();
  };

  const valueAdornment = (
    <Tooltip
      title={intl.formatMessage({ id: "value_tooltip" })}
      arrow
      placement={"bottom-end"}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: colors.blueGray500,
            color: "#FFFFFF",
            fontFamily: "NotoSans-Regular",
            fontSize: "12px",
            letterSpacing: 0,
            lineHeight: "17px",
            padding: "8px",
            width: "179px",
          },
        },
      }}
    >
      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
        <Box sx={{ cursor: "pointer" }}>
          <InfoIcon style={{ cursor: "pointer" }} fill={colors.iconColor} />
        </Box>
      </InputAdornment>
    </Tooltip>
  );

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {selectedRule
            ? intl.formatMessage({ id: "update_rule" })
            : intl.formatMessage({ id: "add_new_rule" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Box sx={{ width: "376px" }}>
          <Box>
            <BasicTextField
              label={intl.formatMessage({ id: "enter_alarm_name" }) + "*"}
              type={"text"}
              form={form}
              formItemLabel={"name"}
              rules={rulesRequired}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "description" })}
              type={"text"}
              form={form}
              formItemLabel={"description"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "email_address" })}
              type={"text"}
              form={form}
              formItemLabel={"emails"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicSelectWithCheckMark
              menuItems={attributeOptions}
              form={form}
              label={intl.formatMessage({ id: "set_attribute" }) + "*"}
              formItemLabel={"attribute_name"}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPaperSx={menuPaperSx}
              rules={rulesRequired}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicSelectWithCheckMark
              menuItems={
                attributeName !== "status"
                  ? conditionOptions
                  : conditionOptionsNE
              }
              form={form}
              label={intl.formatMessage({ id: "set_condition" }) + "*"}
              formItemLabel={"type"}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPaperSx={menuPaperSx}
              rules={rulesRequired}
            />
          </Box>
          {attributeName !== "status" ? (
            <Box sx={{ marginTop: "16px" }}>
              <BasicTextField
                label={intl.formatMessage({ id: "enter_value" }) + "*"}
                type={"text"}
                form={form}
                formItemLabel={"value"}
                variant={"outlined"}
                size={"small"}
                InputLabelProps={inputLabelProps}
                InputProps={{
                  ...inputProps,
                  endAdornment: valueAdornment,
                }}
                rules={rulesRequired}
              />
            </Box>
          ) : (
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelectWithCheckMark
                menuItems={valueOptions}
                form={form}
                label={intl.formatMessage({ id: "enter_value" }) + "*"}
                formItemLabel={"value"}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
                rules={rulesRequired}
              />
            </Box>
          )}
          <Box sx={{ marginTop: "16px" }}>
            <BasicSelectWithCheckMark
              menuItems={criticalityOptions}
              form={form}
              label={intl.formatMessage({ id: "select_criticality" }) + "*"}
              formItemLabel={"severity"}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPaperSx={menuPaperSx}
              rules={rulesRequired}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleSave}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
            disabled={!name || !attributeName || !type || !value || !severity}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AlarmRuleDialog;
