import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import LayoutSelect from "../LayoutSelect";
import DeleteDeviceDialog from "../../common/DeleteDeviceDialog";
import { DarkModeContext, DeleteIcon } from "@datwyler/shared-components";

const SelectionOptions = (props) => {
  const {
    layout,
    setLayout,
    batchUpdate,
    selectedDevices,
    setSelectedDevices,
    tenantId,
  } = props;
  const intl = useIntl();
  const [isDeleteDeviceDialogOpen, setIsDeleteDeviceDialogOpen] =
    useState(false);
  const { colors }: any = useContext(DarkModeContext);

  const handleDelete = () => {
    setIsDeleteDeviceDialogOpen(true);
  };

  const handleAfterDelete = () => {
    setSelectedDevices([]);
  };

  const closeDialog = () => {
    setIsDeleteDeviceDialogOpen(false);
  };

  return (
    <Box>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Tooltip
          title={intl.formatMessage({ id: "delete" })}
          arrow
          placement={"bottom-end"}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: colors.gray800,
                color: colors.white,
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: "-0.2px",
                lineHeight: "19px",
                padding: "4px 8px",
              },
            },
            arrow: {
              sx: {
                color: colors.gray800,
              },
            },
          }}
        >
          <IconButton
            onClick={handleDelete}
            sx={{
              display: "inline-flex",
              width: "48px",
              height: "44px",
              marginLeft: "24px",
              borderRadius: "8px",
            }}
          >
            <DeleteIcon fill={colors.iconColor} />
          </IconButton>
        </Tooltip>
        <LayoutSelect layout={layout} handleChangeLayout={setLayout} />
        <DeleteDeviceDialog
          isOpen={isDeleteDeviceDialogOpen}
          devices={selectedDevices}
          batchUpdate={batchUpdate}
          handleAfterDelete={handleAfterDelete}
          closeDialog={closeDialog}
          tenantId={tenantId}
        />
      </Box>
    </Box>
  );
};

export default SelectionOptions;
