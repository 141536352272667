import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import {
  BasicDataTable,
  BasicPagination,
  AlarmBullet,
  getRequiredDateFormat,
  DarkModeContext,
  DeleteIcon,
} from "@datwyler/shared-components";

const headers = [
  "name",
  "description",
  "properties",
  "alarm_tbl_col_header_severity",
  "date",
  "actions",
];

const columnConfigs = {
  checkbox: {
    colspan: 1,
    isCentralized: false,
  },
  name: {
    colspan: 2,
    isCentralized: false,
  },
  description: {
    colspan: 2,
    isCentralized: false,
  },
  properties: {
    colspan: 3,
    isCentralized: false,
  },
  alarm_tbl_col_header_severity: {
    colspan: 1.5,
    isCentralized: false,
  },
  date: {
    colspan: 1.5,
    isCentralized: false,
  },
  actions: {
    colspan: 1,
    isCentralized: true,
  },
};

const AlarmRule = (props) => {
  const {
    alarmRules,
    openDialog,
    setSelectedAlarmRules,
    selectedAlarmRules,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const [tableData, setTableData] = useState([]);

  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    setTableData(getData());
  }, [alarmRules]);

  useEffect(() => {
    setTableData(getData());
  }, [page, rowsPerPage]);

  const getData = () => {
    const data = [];

    if (alarmRules) {
      let temp = JSON.parse(JSON.stringify(alarmRules || []));

      temp.forEach((rule) => {
        const existingRow = tableData.find(
          (d) => d.additionalInfo?.id === rule?.id
        );
        data.push({
          isChecked: existingRow?.isChecked || false,
          name: rule.name,
          description: rule.description || " ",
          properties: getProperties(rule.properties),
          alarm_tbl_col_header_severity: (
            <>
              <AlarmBullet severity={rule.severity} width={7} height={7} />
              <Typography
                sx={{
                  fontFamily: "NotoSans-Regular",
                  color: colors.dataTableFont,
                  fontSize: "14px",
                  letterSpacing: "0.02px",
                  lineHeight: "20px",
                }}
              >
                {intl.formatMessage({ id: rule.severity?.toLowerCase() })}
              </Typography>
            </>
          ),
          date: getRequiredDateFormat(
            parseInt(rule.updatedOn),
            "DD-MM-YYYY HH:mm"
          ),
          additionalInfo: rule,
          moreButtonMenuItems: getMoreButtonMenu(rule),
        });
      });
    }

    return data;
  };

  const getProperties = (properties) => {
    return (
      intl.formatMessage({ id: properties.attribute_name }) +
      " " +
      intl.formatMessage({ id: properties.type }).toLocaleLowerCase() +
      " " +
      properties.value
    );
  };

  const getMoreButtonMenu = (rule) => {
    return [
      {
        label: intl.formatMessage({ id: "update_rule" }),
        value: rule,
        onClick: () => {
          setSelectedAlarmRules([rule]);
          openDialog("AlarmRuleDialog");
        },
      },
      {
        label: intl.formatMessage({ id: "delete" }),
        value: rule,
        onClick: () => {
          setSelectedAlarmRules([rule]);
          openDialog("DeleteAlarmRuleDialog");
        },
        menuItemFontStyle: {
          color: colors.red500,
        },
        menuItemHoverStyle: {
          color: colors.red500,
        },
      },
    ];
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;
        let tempSelected = JSON.parse(JSON.stringify(selectedAlarmRules));

        if (tempSelected.length < 1) {
          tempSelected.push(row.additionalInfo);
        } else {
          const index = tempSelected.findIndex(
            (s) => s.id === additionalInfo.id
          );
          if (index > -1 && !row.isChecked) {
            tempSelected.splice(index, 1);
          } else if (row.isChecked) {
            tempSelected.push(row.additionalInfo);
          }
        }

        setSelectedAlarmRules(tempSelected);
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    const tempSelected = [];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
      if (e.target.checked) {
        tempSelected.push(row.additionalInfo);
      }
    });

    setSelectedAlarmRules(tempSelected);
    setTableData(temp);
  };

  const handleClickRow = (additionalInfo) => {
    handleOnCheck(additionalInfo);
  };

  const isAnyRowChecked = () => {
    const found = tableData.find((el) => el.isChecked);
    if (found) return true;

    return false;
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      {isAnyRowChecked() && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: colors.cardBg,
            marginBottom: "25px",
          }}
        >
          <IconButton
            onClick={() => openDialog("DeleteAlarmRuleDialog")}
            sx={{
              display: "inline-flex",
              width: "48px",
              height: "44px",
              borderRadius: "8px",
            }}
          >
            <DeleteIcon fill={colors.iconColor} />
          </IconButton>
        </Box>
      )}
      <BasicDataTable
        headers={headers}
        data={tableData}
        columnConfigs={columnConfigs}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default AlarmRule;
